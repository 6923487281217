import * as React from 'react'
import { Container } from 'react-bootstrap'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import cn from 'classnames'
import {
  member_wrap,
  member_pic,
  member_heading,
  member_position,
  member_features,
  member_item,
  member_title,
  member_content,
  list,
} from './style.module.scss'

const Member = ({ data: { member } }) => {
  const { name, position, photo, descr, features } = member

  return (
    <Layout>
      <Container>
        <SEO title={name} description={member.descr?.join(' ')} image={photo.publicUrl} />
        <div className={member_wrap}>
          <div className={member_pic}>
            <GatsbyImage image={photo.childImageSharp.gatsbyImageData} alt={name} style={{ display: 'block' }} />
          </div>
          <h1 className={member_heading}>
            {name} {position && <span className={member_position}>{position}</span>}
          </h1>
          {descr && descr.map((el) => <p key={el}>{el}</p>)}
          {features.length && (
            <ul className={member_features}>
              {features.map(({ title, content }) => (
                <li key={title} className={member_item}>
                  <p className={member_title}>{title}</p>
                  {content.length > 1 ? (
                    <ul className={cn(member_content, list)}>
                      {content.map((item, i) => (
                        <li key={`line${i}`}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <p className={member_content}>{content}</p>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default Member

export const pageQuery = graphql`
  query teamQuery($slug: String!) {
    member: teamJson(slug: { eq: $slug }) {
      name
      position
      descr
      features {
        title
        content
      }
      photo {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 464, quality: 100, aspectRatio: 0.875, placeholder: BLURRED)
        }
        publicURL
      }
    }
  }
`
